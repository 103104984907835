<template>
  <router-view></router-view>
</template>
<script>
  export default {
    props: ['type'],
    watch: {
      type() {
        this.setAssetType()
      }
    },
    methods: {
      setAssetType() {
        this.$store.commit('graphql/setAssetType', this.type)
      }
    },
    created() {
      this.setAssetType()
    }
  }
</script>